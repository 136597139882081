import {
  useGetDashboardSummaryQuery, 
  useGetGuidedToursDetailQuery, 
  useLinkCodeQuery,
  // useUpdateTourStatusMutation
} from "../../features/api/homeSlice";
import { DashboardCard } from "../reusables/DashboardCard";
import {
  copyToClipboard,
  // getTourDetail,
  numberWithCommasWithoutDecimal,
} from "../../helper";
import { QuickActionCard } from "../reusables/QuickActionCard";
import { useState } from "react";
import Joyride from "react-joyride";
import { IntroducingMTNDashboard } from "./OnboardingMTNDashboard";
import CopyIcon from "../../assets/icons/copy.svg";
import { quickActionData } from "./data";
import ScaleLoader from "react-spinners/ScaleLoader";
import JoyrideSteps from "./JoyrideSteps";
import { SeeTourButton } from "../reusables";
import { useAppSelector } from "src/app/hooks";
import { Tour } from "src/features/api/homeSliceTypes";


const Dashboard = () => {
  const { userId } = useAppSelector((state) => state.auth);
  const [isJoyrideActive, setIsJoyrideActive] = useState(false);
  const [tourDetail, setTourDetail] = useState<Tour>();
  const [isCloseModal, setIsCloseModal] = useState(true);
  const [copiedText, setCopiedText] = useState<string>('');

  const { data, isLoading } = useGetDashboardSummaryQuery();

  const {data: guidedTours} = useGetGuidedToursDetailQuery(userId, {
    skip:!userId,
  })

  // const [updateTour] = useUpdateTourStatusMutation();
  console.log(guidedTours, setTourDetail, 'i am guided tour')

  const {data: linkCode, isLoading: gettingLinkCode} = useLinkCodeQuery();

  // const closeTourModal = () =>{
  //   let data = {
  //     tourId: tourDetail?.id || '',
  //     data: {
  //       userId: userId,
  //       isCompleted: true
  //     } 
  //   }
  //   updateTour(data);
  //   setIsCloseModal(false)
  // }


  const startJoyride = () => {
    setIsJoyrideActive(true);
  };

  // const startJoyride = () => {
  //   setIsJoyrideActive(true);
  // };

   // Shows the tour steps
  const steps = JoyrideSteps();

  const customStyles = {
    options: {
      arrowColor: "#000",
      primaryColor: "black",
      color: "white",
      width: 250,
      zIndex: 1000,
      borderRadius: "20px",
      backgroundColor: "#000",
    },
    spotlight: {
      borderRadius: "18px",
    },
    buttonNext: {
      backgroundColor: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#1b1b1b",
      color: "#1b1b1b",
      borderRadius: "40px",
      fontSize: "0.625rem",
      padding: "4px 12px",
      fontWeight: "600",
      marginLeft: "12px",
    },
    buttonBack: {
      color: "white",
      borderRadius: "40px",
      fontSize: "0.625rem",
    },
  };

  const handleCopyClick = () => {
    const code = linkCode?.data?.linkCode || ""
    copyToClipboard(code);
    setCopiedText(code);

    setTimeout(() => {
        setCopiedText('');
    }, 3000);
  };

  const handleSeeTourClick = () => {
    setIsJoyrideActive(true);
  };

  // useEffect(() => {
  //   if (guidedTours?.success) {
  //     const homeTour = getTourDetail(guidedTours.data, 'home');
  //     if (homeTour) {
  //       setTourDetail(homeTour);
  //       setIsJoyrideActive(homeTour?.isCompleted);
  //     }
  //   }
  // }, []);
  


  return (
    <section>
      <Joyride
        continuous
        callback={() => {}}
        run={isJoyrideActive}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        styles={customStyles}
      />
      {!tourDetail?.isCompleted && !isJoyrideActive && isCloseModal && <IntroducingMTNDashboard handleGetStarted={startJoyride} handleCloseModal={()=> setIsCloseModal(false)} />}
      <div className="relative bg-clrBg w-[96%] mx-auto mt-3 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
        <div className="p-4 pb-8 mb-3 bg-white sm:p-6 dark:bg-clrDarkBg rounded-xl">
          <div className="flex flex-wrap justify-between gap-4 mb-8 md:items-center">
            <div className="">
              <p className="text-base font-semibold text-primary md:text-lg"> Welcome Back</p>
              <p className="text-clrGray text-x10 md:text-xs"> what do you hope to achieve today?</p>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full gap-6 md:justify-start md:w-auto">
              <SeeTourButton handleClick={handleSeeTourClick} />
              <div id="step-1" className="flex flex-col gap-1 py-1 bg-white dark:bg-clrDarkBg border border-solid border-[#C4C4C433] rounded-lg w-[140px] md:w-[180px]">
                <p className="text-x9 md:text-x10 font-normal text-[#9f9f9f] text-center dark:text-clrPlaceholder whitespace-break-spaces">
                  My Link Code
                </p>
                <p className="relative flex justify-center gap-2">
                  <span className="text-lg md:text-xl font-semibold text-[#1d1d1d] dark:text-white">
                    {gettingLinkCode 
                    ?
                    <ScaleLoader
                      loading={isLoading}
                      height={12}
                      width={2}
                      color={'#3d5170'}
                    />
                    : linkCode?.data?.linkCode}
                  </span>
                  <img
                      src={CopyIcon}
                      alt="Copy this Code"
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={handleCopyClick}
                  />
                  {linkCode?.data?.linkCode === copiedText && (
                      <p className="absolute p-1 px-2 mt-4 text-white border-solid rounded right-3 top-1 bg-primary text-x10 ">
                          {' '}
                          Copied
                      </p>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div> 
            <p className="mb-2 text-xs font-medium text-black"> Quick Actions</p>
          </div>
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 xl:grid-cols-4 md:gap-y-4 md:gap-x-6">
            {quickActionData.map((action, index) => {
              return (
                <QuickActionCard key={index} id={action.id} icon={action.icon} link={action.link} title={action.title}/>
              )
            })}
          </div>
        </div>
        {/* overview */}
        <div id="step-10" className="p-4 pb-8 bg-white sm:p-6 dark:bg-clrDarkBg rounded-xl">
          <div> 
            <p className="mb-2 text-xs font-medium text-black">Overview</p>
          </div>
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 xl:grid-cols-4 md:gap-y-4 md:gap-x-6">
            <DashboardCard
              isLoading={isLoading}
              title={"Hotspot Hubs"}
              value={data?.data?.totalHotspotHubs || 0}
              link={"/home/hotspot-hubs"}
            />
            {/* <DashboardCard
              isLoading={isLoading}
              title={"Agents"}
              value={data?.data?.totalAgents || 0}
              link={"/home/agents"}
            /> */}
            {/* <DashboardCard
              isLoading={isLoading}
              title={`Advert Revenue`}
              value={numberWithCommas(data?.data?.revenueInUSD || 0) || 0}
              link={"/home/wallet"}
            /> */}
            <DashboardCard
              isLoading={isLoading}
              title={`Tokens`}
              value={data?.data?.numberOfTokensGenerated || 0}
              link={"/home/token"}
            />
            <DashboardCard
              isLoading={isLoading}
              title={`Sessions`}
              value={
                numberWithCommasWithoutDecimal(data?.data?.sessionsCount || 0) || 0
              }
              // info={"These are all connections to this user's devices"}
            />
          </div>
        </div>

      </div>

    </section>
  );
};

export default Dashboard;
