import ArrowRightYellow from "../../assets/icons/arrow-down-Yellow.svg";
import Profile from "../../assets/profile-pic.svg";
import LogOut from "../../assets/icons/logout.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logOut } from "../../features/store/authReducerSlice";
import { Link } from "react-router-dom";
import LightDark from "./LightDarkMode";
import { StatusProps } from "../hotspotHubs/types";
import { useGetBusinessProfileQuery, useGetUserProfileQuery } from "src/features/api/authSlice";

const TopRightMenu = ({ modalRef }: StatusProps) => {
  const { userId} = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  

  const { data: userProfile } = useGetUserProfileQuery(userId, {
    skip:!userId,
  });

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();

  // in order of hierachy, decide which account
  // type a logged in user has
  const renderAccountType = () => {
    return "User Account";
  };

  return (
    <>
      <div className="absolute z-30 py-4 px-2 bg-white dark:bg-clrDarkBg rounded-md shadow bottom-[2rem] top-auto md:top-[2.1rem] md:bottom-auto -right-[1.8rem] md:right-[0.2rem] w-60">
        <Link to="/home/profile/info" className="flex items-center justify-between">
          <img
            src={businessProfile? businessProfile?.data?.profileUrl : Profile}
            alt="Profile"
            className="self-start"
            width={35}
            height={35}
          />
          <div>
            <h3 className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder">
              {userProfile?.data?.name}
            </h3>
            <p className="mb-0.5 font-normal text-x9 text-clrlightGray dark:text-clrPlaceholder">
              {userProfile?.data?.email}
            </p>
            <button className="p-1 text-x8 font-semibold bg-white dark:bg-inherit tracking-wide border-[1px] border-solid rounded text-clrDarkYellow border-clrBorder">
              {renderAccountType()}
            </button>
          </div>
          <img
            src={ArrowRightYellow}
            alt="Navigation arrow"
            className="self-start transform -rotate-90"
            width={18}
            height={18}
          />
        </Link>
        <div className="pl-2">
          <LightDark />
          <div
            className="flex gap-4 mb-4 cursor-pointer"
            onClick={() => dispatch(logOut())}
          >
            <img src={LogOut} alt="Log out" width={18} height={18} />
            <h3 className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder">
              Log out
            </h3>
          </div>
        </div>
      </div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-transparent"
        ref={modalRef}
      ></section>
    </>
  );
};

export default TopRightMenu;
